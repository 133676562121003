@import "@livingmap/core-ui-v2";

.container {
  background: $white-tone;
  box-shadow:
    0px 0px 12px rgba($black-tone, 0.15),
    0px 4px 12px 5px rgba($black-tone, 0.05);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;

  .header {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    &.separator {
      border-bottom: 1px solid $grey-tone-300;
    }
  }

  .content {
    overflow-y: auto;
    padding: 20px;
    margin-bottom: 10px;
  }

  .minMaxToggle {
    position: absolute;
    width: 56px;
    height: 24px;
    left: calc(50% - 56px / 2 - 1px);
    bottom: -24px;
    background: $grey-tone-900;
    box-shadow:
      0px 0px 12px rgba($black-tone, 0.15),
      0px 4px 12px 5px rgba($black-tone, 0.05);
    border-radius: 0px 0px 8px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: $white-tone;
  }
}
