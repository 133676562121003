@import "@livingmap/core-ui-v2";

.container {
  display: flex;
  flex-direction: column;

  .departuresList {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;

    border-radius: 8px;
    @include background-overlay($grey-tone-900, 0.04);

    .departureListItem {
      display: flex;
      padding-bottom: 12px;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;
      border-bottom: 1px solid $grey-tone-200;

      &:last-child {
        border-bottom: none;
        padding-bottom: 0;
      }

      .departureTime {
        @include font-title-small;
        display: flex;
        width: 52px;
        align-items: flex-start;
        align-self: stretch;
      }

      .serviceDetails {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        flex: 1 0 0;

        .destinationAndTime {
          display: flex;
          align-items: center;
          gap: 8px;
          align-self: stretch;
          @include font-label-large;

          .departure {
            display: flex;
            align-self: flex-start;
            flex: 1 0 0;
          }

          .platform {
            display: flex;
            justify-content: flex-end;
            align-self: flex-start;
          }
        }

        .operatorAndPlatform {
          display: flex;
          justify-content: space-between;
          align-items: center;
          align-self: stretch;
          @include font-label-medium;

          .operator {
            display: flex;
            justify-content: flex-end;
            align-items: flex-start;
            gap: 10px;
            align-self: stretch;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            flex: 1 0 0;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .time {
            display: flex;
            justify-content: flex-end;
            align-items: flex-start;
            gap: 10px;
            align-self: stretch;
            text-align: right;

            .onTime {
              color: $green-tone-700;
            }

            .delayed {
              color: $red-tone-800;
            }
          }
        }
      }

      .chevronIcon {
        display: flex;
        width: 24px;
        justify-content: center;
        align-items: center;
        color: $grey-tone-700;
        align-self: stretch;
      }
    }
  }

  .loadMoreButton {
    width: 113px;
    align-self: center;
    border: 1px solid $grey-tone-300 !important;
    color: $grey-tone-900 !important;
    margin-bottom: 16px;
    white-space: nowrap;

    &:hover {
      @include background-overlay($grey-tone-900, 0.04);
    }
  }
}
