@import "@livingmap/core-ui-v2";

.dialogBody {
  color: $grey-tone-900;

  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .qrCode {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .svg {
      max-width: 160px;
      max-height: 160px;
    }

    .description {
      @include font-label-medium;
      margin-top: 20px;
    }
  }
}

.urlContainer {
  border: 1px solid $grey-tone-300;
  border-radius: 8px;
  padding: 12px;
  margin: 0 0 12px;

  .url {
    @include font-body-medium;
    width: 100%;
    white-space: nowrap;
    overflow-x: scroll;
    margin: 0;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.copyToClipboardButton {
  justify-content: center;
  width: 100%;
}
