@import "@livingmap/core-ui-v2";

.noDepartures {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: 4px;

  border-radius: 8px;
  @include background-overlay($grey-tone-900, 0.04);

  .title {
    @include font-label-large;
  }

  .subtitle {
    @include font-label-medium;
    color: $grey-tone-700;
  }
}
