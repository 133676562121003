@import "@livingmap/core-ui-v2";

.container {
  flex-direction: row;
  display: flex;
  align-items: center;

  .inputContainer {
    position: relative;
    width: 100%;
    display: flex;
    overflow: hidden;
    border-radius: 100px;
    box-shadow:
      0px 0px 12px rgba($black-tone, 0.15),
      0px 4px 12px 5px rgba($black-tone, 0.05);

    + .cancelButton {
      @include font-label-large;
      display: none;
      background: none !important;

      &:active {
        display: block;
      }
    }

    &:focus-within {
      + .cancelButton {
        display: block;
      }

      &.flatInput {
        box-shadow: none;
        border: 1px solid transparent;
        background: rgba($grey-tone-900, 0.04);

        &.dirty {
          box-shadow: none;
        }
      }
    }

    &:not(:focus-within) {
      .closeIcon {
        display: block;
      }

      &.flatInput {
        &.dirty {
          box-shadow: none;
          border: 1px solid $grey-tone-300;
        }
      }
    }

    .input {
      @include font-label-large;
      height: 48px;
      background: transparent;
      position: relative;
      overflow: hidden;
      width: 100%;
      outline: none;
      padding: 0 45px 0 45px;
      border: 1px solid transparent;
      -webkit-appearance: none;
      appearance: none;
      display: flex;

      &::placeholder {
        opacity: 0;
      }

      &:not(.flatInput) {
        background: $white-tone;
      }

      &.compact {
        padding: 0 40px 0 40px;
      }
    }

    .input:placeholder-shown ~ .placeholder {
      @include font-label-large;

      &.alignCenter {
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .input:focus ~ .placeholder {
      color: $grey-tone-500;

      &.alignCenter {
        left: 20px;
        transform: translate(
          0%
        ) !important; // needs important because rtl package does not pick up 0 values

        &.compact {
          left: 15px;
        }
      }
    }

    .input:focus ~ .clearIcon {
      display: flex;
    }

    .input:not(:placeholder-shown) ~ .placeholder {
      color: $grey-tone-500;

      &.compact {
        left: 15px;
      }
    }

    .input:not(:placeholder-shown) ~ .placeholder > .placeholderText {
      display: none;
    }

    .input:not(:placeholder-shown) ~ .placeholder > .icon {
      color: $grey-tone-900;
    }

    .placeholderText {
      &:not(.compact) {
        position: relative;
        top: 1px;
      }
    }

    .placeholder {
      height: 22px;
      position: absolute;
      pointer-events: none;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: calc(100% - 30px);
      left: 20px;
      top: 13px;

      .iconContainer {
        margin-right: 10px;
        width: 16px;
        height: 16px;
        display: inline-flex;

        svg {
          color: $grey-tone-900;
          position: absolute;
        }
      }
    }

    .clearIcon,
    .closeIcon {
      cursor: pointer;
      display: none;
      position: absolute;
      right: 20px;

      &.compact {
        right: 15px;
      }
    }

    .clearIcon {
      top: 15px;
    }

    .closeIcon {
      top: 18px;
    }

    .noBorderClearIcon {
      top: 18px;
      right: 20px;

      &.compact {
        right: 15px;
      }
    }
  }
}
