@import "@livingmap/core-ui-v2";
@import "@livingmap/hippo";

.panelContainer {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 1;
  width: 360px;

  .searchControlsContainer {
    display: flex;

    .languageButtonContainer {
      margin-top: 4px;
      position: absolute;
      right: 0;
      transform: translate(calc(100% + 8px));

      .languageButton {
        margin-bottom: 8px;
      }

      .languageSelectPanel {
        position: absolute;
        top: 100%;
        left: 0;
        @include font-title-small;

        display: none;
        flex-direction: column;
        gap: 20px;

        &.activeHover:hover {
          display: flex;
        }

        ul {
          margin: 0;
          padding: 0;
          list-style: none;

          display: flex;
          flex-direction: column;
          gap: 8px;
        }
      }

      &:focus-within .languageSelectPanel {
        display: flex;
      }
    }
  }

  .searchResultsContainer {
    position: relative;
    margin-bottom: 10px;
    flex: 1;

    .searchItems {
      display: none;
    }

    .activeSelect {
      background-color: rgba($grey-tone-900, 0.04);
    }

    &:focus-within .searchItems {
      display: block;
    }

    .searchItems {
      position: absolute;
      transform: translateY(100%);
      bottom: -10px;
      z-index: 1;
      width: 100%;
      padding: 10px 0;
      overflow: scroll;
      max-height: 550px;

      &.activeHover:hover {
        display: block;
      }

      .searchResult {
        padding: 8px 20px;
      }
    }
  }
}
