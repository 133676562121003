.container {
  position: relative;

  .image {
    border-radius: 8px;
    width: 100%;
  }

  .panoramaButton {
    position: absolute;
    left: 12px;
    bottom: 12px;
    > span {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
}
