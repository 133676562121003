@import "@livingmap/core-ui-v2";

.container {
  // Light theme
  &.light {
    &.searching {
      svg {
        animation: pulse-light 1s ease-in-out infinite;
      }
    }

    &.found {
      svg {
        color: $blue-tone-600;
      }
    }
  }

  // Dark theme
  &.dark {
    &.searching {
      svg {
        animation: pulse-dark 1s ease-in-out infinite;
      }
    }

    &.found {
      svg {
        color: $blue-tone-600;
      }
    }
  }
}

@keyframes pulse-light {
  50% {
    color: $grey-tone-400;
  }
}

@keyframes pulse-dark {
  50% {
    color: $grey-tone-500;
  }
}
