@import "@livingmap/core-ui-v2";

@import "../../../../utils/animations.scss";

.header {
  .routeInputContainer {
    display: flex;
    margin-bottom: 20px;

    .closeButton {
      height: 14px;
      width: 14px;
      margin: 9px 0 0 21px;
    }
  }

  .stepFreeToggleContainer {
    padding-bottom: 18px;
  }

  .routeSummaryContainer {
    border-top: 1px solid $grey-tone-300;
    margin: 0 -20px;

    .textSkeleton {
      height: 20px;
      background: rgba($grey-tone-900, 0.04);
      border-radius: 4px;
      width: 160px;
      margin: 26px 20px 6px;

      @extend .skeletonBoxAnimation;
    }

    .routeError {
      color: $grey-tone-900;
      text-align: left;
      margin: 23px 20px 3px;

      @extend .font-title-medium;
    }
  }
}

.emptyContent {
  margin: 0 -20px;
  padding: 0 20px;
  border-top: 1px solid $grey-tone-300;
}
