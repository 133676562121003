/*rtl:begin:ignore*/

@import "@livingmap/core-ui-v2";

@import "../../utils/variables.scss";

.container {
  @include font-label-medium;
  position: relative;
  width: 100%;

  .arrowLeft,
  .arrowRight {
    position: absolute;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    z-index: 1;
    height: 32px;
    width: 32px;

    svg {
      height: 12px;
    }
  }

  .arrowLeft {
    left: -20px;
  }

  .arrowRight {
    right: -20px;
  }

  .sliderContainer {
    overflow: hidden;

    .slider {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 8px 0;

      .tagWrapper {
        flex-shrink: 0;

        .tag {
          box-shadow: 0px 1px 6px 2px rgba($black-tone, 0.1);

          &:not(.active):hover {
            background-color: $grey-tone-200;
          }
        }
      }
    }

    @include mobile-media-query {
      padding-left: 20px;
      margin: 8px -20px 0;
    }
  }
}

:global(.centre-slider) {
  justify-content: center !important;
  transform: none !important;
}

/*rtl:end:ignore*/
