@import "@livingmap/core-ui-v2";

@import "../../utils/scaleFunctions.scss";

$size: 24px;

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;

  /* Dark theme */
  &.dark {
    background: $grey-tone-900;
    color: $grey-tone-050;

    .svg {
      color: $grey-tone-050;
    }
  }

  /* Light theme */
  &.light {
    background: $grey-tone-050;
    color: $grey-tone-400;

    .svg {
      color: $grey-tone-900;
    }
  }

  /* Small styles */
  &.small {
    width: 40px;
    height: 40px;
    box-shadow: 0px 10px 24px 0px rgba($grey-tone-900, 0.3);
  }

  /* Medium styles */
  &.medium {
    width: scaleMedium(40px);
    height: scaleMedium(40px);
    box-shadow: scaleMedium(0px 10px 24px 0px rgba($grey-tone-900, 0.3));
  }

  /* Large styles */
  &.large {
    width: scaleLarge(40px);
    height: scaleLarge(40px);
    box-shadow: scaleLarge(0px 10px 24px 0px rgba($grey-tone-900, 0.3));
  }
}
