@import "@livingmap/core-ui-v2";

.container {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;

  border-radius: 8px;
  list-style-type: none;
  @include background-overlay($grey-tone-900, 0.04);

  .listItem {
    display: flex;
    align-items: center;
    padding: 12px 16px;
    border-bottom: 1px solid $grey-tone-200;

    &:last-child {
      border-bottom: none;
    }

    .chip {
      margin-right: 8px;
    }

    .destination,
    .departureTime {
      @include font-label-medium;
    }

    .departureTime {
      white-space: nowrap;
    }

    span:last-child {
      margin-left: auto;
      text-align: right;
    }
  }

  .divider {
    height: 1px;
    width: 100%;
    background: $grey-tone-200;
    margin: 12px 0;

    &:last-child {
      display: none;
    }
  }
}
