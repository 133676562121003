@import "@livingmap/core-ui-v2";

.timeToDestination {
  padding: 0 16px;
  min-height: 64px;
  order: -1;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .titleContainer {
    display: flex;
    flex: 1;
    align-items: center;
  }

  .title {
    @include font-title-medium;
    color: $grey-tone-900;
    padding-left: 12.5px;
    margin: 0;
  }

  .closeButton {
    margin-right: -13px;
  }
}
