@import "@livingmap/core-ui-v2";
$spacing: 20px;

.container {
  color: $grey-tone-900;
  display: flex;
  margin-top: 16px;
  width: fit-content;
  padding: 2px 0;
  @include font-body-medium;

  .icon {
    margin-right: $spacing;
    width: $spacing;
    height: $spacing;
    margin-top: 4px;
  }

  .body {
    width: 100%;
    word-break: break-word;
    align-self: center;
    unicode-bidi: plaintext;
  }
}
