@import "@livingmap/core-ui-v2";

.container {
  background: $white-tone;
  box-shadow:
    0px 0px 12px rgba($black-tone, 0.15),
    0px 4px 12px 5px rgba($black-tone, 0.05);
  border-radius: 16px;
  padding: 20px;
}
