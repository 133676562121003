@import "@livingmap/core-ui-v2";

.container {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;

  .header {
    @include font-title-small;
    padding: 8px 16px;
  }

  .departuresList {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    margin: 0;

    @include background-overlay($grey-tone-900, 0.04);
    list-style-type: none;

    .noUpcomingDepartures {
      color: $grey-tone-900;
      @include font-title-medium;
    }

    .destination {
      display: flex;
      justify-content: space-between;
      width: 100%;
      border-bottom: 1px solid $grey-tone-200;
      padding-bottom: 12px;

      &:last-child {
        border-bottom: none;
        padding-bottom: 0;
      }

      .destinationInfo {
        display: flex;
        flex-direction: column;

        .destinationName {
          @include font-label-large;
        }

        .destinationPlatform {
          @include font-label-medium;
          margin-top: 4px;
        }
      }

      .departureTimes {
        display: flex;
        flex-direction: column;
        text-align: right;

        .nextDeparture {
          @include font-label-large;
          white-space: nowrap;
        }

        .futureDepartures {
          @include font-label-medium;
          margin-top: 4px;
        }
      }
    }
  }
}
