@import "@livingmap/core-ui-v2";

.container {
  @include font-body-medium;
}

.readMore {
  @include font-label-medium;
  cursor: pointer;
}
