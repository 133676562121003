@import "../../utils//variables.scss";

.quickSearchTags {
  position: absolute;
  bottom: 16px;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, 0);

  @include mobile-media-query {
    position: relative;
    bottom: 0;
    width: 100%;
  }
}
