@import "@livingmap/core-ui-v2";

@import "../../../../utils/variables.scss";

.warning {
  @include font-body-small;
  align-items: center;
  border-bottom: 1px solid $grey-tone-300;
  border-top: 1px solid $grey-tone-300;
  color: $grey-tone-500;
  display: flex;
  justify-content: center;
  margin: 0 -20px;
  padding: 0 20px;

  .icon {
    margin-right: 21px;
    margin-left: 9px;
  }

  .text {
    margin: 16px 0;
  }
}

.routeMilestones {
  margin: 12px -20px;

  @include mobile-media-query {
    margin: 0 -20px;
  }

  .milestone {
    &:hover {
      background-color: unset;
      cursor: inherit;
    }

    @include mobile-media-query {
      margin-top: 12px;
    }
  }
}
