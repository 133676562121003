@import "@livingmap/core-ui-v2";

.separator {
  display: flex;
  padding-left: 20px;
  cursor: pointer;
  width: 100%;
  background-color: $white-tone;
  align-items: center;

  .line {
    border: $grey-tone-300 solid 1px;
    height: 0;
    flex-grow: 1;
  }
  .text {
    @include font-label-medium-prominent;
    color: $grey-tone-900;
    width: fit-content;
    padding: 0 10px 0 5px;
  }
}
