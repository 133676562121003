@import "@livingmap/core-ui-v2";

.container {
  display: flex;
  align-items: center;
  border: 1px solid $grey-tone-300;
  border-radius: 12px;
  width: 100%;
}

.swapIconWrapper {
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.contentWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: 10px;

  .inputWrapper {
    display: flex;
    align-items: center;

    &:first-child {
      border-bottom: 1px solid $grey-tone-300;
    }

    .inputIconWrapper {
      width: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
    }

    .clearIcon {
      flex-shrink: 0;
      cursor: pointer;
      margin-left: 10px;

      &.hidden {
        display: none;
      }
    }

    .input {
      @include font-label-large;
      border: none;
      color: $grey-tone-900;
      height: 48px;
      outline: none;
      width: 100%;

      &.systemDefined {
        color: $blue-tone-600;
      }

      &:disabled {
        background: $white-tone;
      }
    }
  }
}
