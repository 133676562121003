@import "@livingmap/core-ui-v2";

.container {
  display: flex;
  padding: 12px 20px;
  cursor: pointer;
  width: 100%;
  background-color: $white-tone;

  &:hover {
    background-color: rgba($grey-tone-900, 0.04);
  }

  &.accent {
    &:hover {
      background-color: rgba($blue-tone-900, 0.08);
    }

    .iconContainer {
      background: rgba($blue-tone-600, 0.06);

      svg {
        color: $blue-tone-600;
      }
    }
  }

  .iconContainer {
    width: 36px;
    height: 36px;
    background: rgba($grey-tone-900, 0.04);
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .contentContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-left: 12px;

    .mainText {
      @include font-label-medium-prominent;
      color: $grey-tone-900;
    }

    .subText {
      @include font-label-small;
      color: $grey-tone-900;
    }
  }

  .time {
    @include font-label-small;
    color: $grey-tone-600;
    margin-left: auto;
    margin-right: 12px;
  }

  &.medium {
    .iconContainer {
      width: 48px;
      height: 48px;
    }

    .mainText {
      @include font-title-medium;
    }

    .subText,
    .time {
      @include font-label-medium;
    }
  }
}
