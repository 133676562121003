@import "@livingmap/core-ui-v2";

@import "../../utils/variables";

.panelContainer {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 1;
  width: 360px;

  .searchbar {
    margin-bottom: 10px;
  }
}

.mobileSearchSheet {
  &.scrollSheet {
    [data-rsbs-scroll] {
      overflow: auto;
    }
  }

  [data-rsbs-scroll] {
    overflow: inherit;
  }
}

.loadingChip {
  position: absolute;
  top: 24px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  cursor: auto;
  padding: 0 20px;

  .spinner {
    margin-right: 10px;
  }

  > span {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  &:hover {
    background: $white-tone;
  }
}

.searchResultsHeader {
  @include font-title-medium;

  .loadingResults {
    display: block;
    height: 14px;
    background: rgba($grey-tone-900, 0.04);
    border-radius: 4px;
    margin-bottom: 6px;
    width: 88px;
  }
}

.searchResultsContainer {
  display: flex;
  flex-direction: column;

  .searchResult {
    padding: 22px 0;
    border-bottom: 1px solid $grey-tone-300;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
  }
}

.noResultsTitle {
  @include font-title-medium;
  color: $grey-tone-900;
  margin: 0;
  margin-bottom: 4px;
}

.noResultsDescription {
  @include font-label-medium;
  color: $grey-tone-700;
  margin: 0;
}

.recentSearchesContainer {
  position: relative;

  .recentSearches {
    display: none;
  }

  input:focus + .recentSearches {
    display: block;
  }

  .recentSearches {
    position: absolute;
    transform: translateY(100%);
    bottom: -10px;
    z-index: 1;
    width: 100%;
    padding: 10px 0;

    &:hover {
      display: block;
    }

    .searchResult {
      padding: 8px 20px;
    }
  }
}

@include mobile-media-query {
  .searchResult {
    padding: 8px 0;
  }

  .searchResultsHeader {
    text-align: left;
    padding: 20px 0;
    border-bottom: 1px solid $grey-tone-300;
    margin: 0 -20px;

    .loadingResults {
      display: block;
      height: 14px;
      background: rgba($grey-tone-900, 0.04);
      border-radius: 4px;
      margin-bottom: 6px;
      width: 88px;
    }

    .results {
      padding: 0 20px;
    }
  }

  .loadingChip {
    bottom: 216px;
    top: auto;
  }

  .searchResultsContainer {
    .searchResult {
      &:last-child {
        padding-bottom: 20px;
      }
    }

    .loadingSearchResult {
      padding: 22px 0;
      border-bottom: 1px solid $grey-tone-300;

      &:first-child {
        padding-top: 0;
      }

      &:first-child {
        border-bottom: 0;
      }
    }
  }
}
