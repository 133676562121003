@import "@livingmap/core-ui-v2";

.panelContainer {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 1;
  width: 360px;

  .container {
    display: flex;
    margin-bottom: 8px;

    .stepFreeToggle {
      margin-top: 16px;
    }

    .closeIcon {
      margin-left: 20px;
      cursor: pointer;
    }
  }

  .desktopQuickActionsPanel {
    padding: 10px 0;

    &.hidden {
      display: none;
    }

    .searchResult {
      padding: 10px 20px;
    }
  }

  .searchResultsContainer {
    padding: 10px 0;

    .searchResults {
      max-height: 400px;
      overflow-y: auto;
    }

    &.hidden {
      display: none;
    }
  }

  .collapsablePanelContent {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin: 0 !important;
  }

  .collapsablePanelFooter {
    display: flex;
    justify-content: center;
    padding: 16px 20px 20px 20px;
    border-top: 1px solid $grey-tone-300;
  }

  .sendDirectionsButton {
    flex: 1;
    display: flex;
    justify-content: center;
  }

  .routingErrorPanel {
    @include font-title-medium;
    color: $grey-tone-900;
  }
}
