@import "@livingmap/core-ui-v2";

.container {
  padding: 12px;
  width: 100%;

  .slide {
    background: $white-tone;
    border-radius: 12px;
    box-shadow:
      0px 4px 12px 5px rgba($black-tone, 0.05),
      0px 0px 12px 0px rgba($black-tone, 0.15);
    min-height: 50px;
    height: 100%;
  }

  .bullets {
    margin-bottom: 20px;

    .bullet {
      background-color: $grey-tone-700;
    }
  }

  .step,
  .step:hover {
    background-color: transparent;
    padding: 12px 6px 40px 12px;
    border-radius: 12px;
  }
}
