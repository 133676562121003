@import "@livingmap/core-ui-v2";

.image {
  margin-bottom: 16px;
}

.panoramicImage {
  animation: fade-in 0.5s ease-in-out;
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
}

.description {
  margin-bottom: 16px;
  background: rgba($grey-tone-900, 0.04);
  border-radius: 8px;
  padding: 8px 12px;

  p:first-child {
    margin: 0 0 4px;
  }

  p:not(:only-child) {
    margin-bottom: 0;
  }
}

.metaData {
  a {
    color: $grey-tone-900;
    text-decoration: none;
  }
}

.transportTimetable {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .transportTimetableTitle {
    @include font-label-medium;
  }
}

@include mobile-media-query {
  .image {
    margin-bottom: 12px;
  }

  .description {
    margin-bottom: 12px;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}
