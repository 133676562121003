@import "@livingmap/core-ui-v2";

@import "../../utils/variables.scss";

:global(.mapboxgl-ctrl-logo) {
  display: none !important;
}

.map {
  width: 100%;
  height: 100%;
}

.container {
  width: 100%;
  height: 100%;
  position: relative;

  .advert {
    padding-bottom: 88px;

    &.extendedPadding {
      padding-bottom: 146px;
    }
  }
}

.loaderContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($white-tone, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.attribution {
  position: absolute;
  left: 24px;
  bottom: 24px;
  color: $grey-tone-900;
  cursor: pointer;
}

.attributionDialog {
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  text-align: center;
  width: 100%;
}

.mapAttributionConfirmButton {
  display: flex;
  justify-content: center;
}

.floorSelectorContainer {
  position: absolute;
  top: 24px;
  right: 24px;
  z-index: 1;
}

.leftMobileControlsContainer {
  display: none;
  position: absolute;
  top: 24px;
  left: 16px;
  pointer-events: none;

  > * {
    pointer-events: all;
  }
}

.mapControlsContainer {
  position: absolute;
  bottom: 20px;
  right: 16px;
  pointer-events: none;

  > * {
    pointer-events: all;
  }
}

.control {
  margin-bottom: 12px;
  white-space: nowrap;

  &.journeyOverviewControl {
    display: none;
  }
}

@include mobile-media-query {
  .floorSelectorContainer {
    right: 50%;
    transform: translateX(50%);
  }

  .leftMobileControlsContainer {
    display: block;
  }

  .mapControlsContainer {
    top: 24px;
  }

  .zoomControl {
    display: none;
  }

  .control.journeyOverviewControl {
    display: block;
  }

  .bottom {
    position: absolute;
    bottom: 93px;
  }

  .attribution {
    bottom: 140px;
    left: 30px;
  }

  .languageSelectSheet {
    position: relative;
    z-index: 4;

    .languageSelectSheetHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    ul {
      margin: 0;
      padding: 0;
      padding-top: 2px;
      padding-bottom: 40px;
      list-style: none;

      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }
}
