@import "../../../../utils/variables.scss";

.container {
  > div {
    padding: 10px 20px;
  }

  @include mobile-media-query {
    > div {
      padding: 0;
    }

    > div:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}
