@import "@livingmap/core-ui-v2";

@import "../../utils/variables.scss";

.container {
  display: flex;
  width: 100%;
  cursor: pointer;
  position: relative;
  align-items: center;

  .iconContainer {
    width: 48px;
    height: 48px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex: 1;
    margin-left: 8px;
    word-break: break-word;

    .title {
      @include font-label-large;
    }

    .subtitle {
      @include font-body-medium;
    }
  }

  .removeIcon {
    position: absolute;
    cursor: pointer;
    top: 25px;
    right: 25px;
  }

  &.grey {
    &:hover {
      background-color: rgba($grey-tone-900, 0.04);

      @include mobile-media-query {
        background-color: $white-tone;
      }
    }

    .iconContainer {
      background: rgba($grey-tone-900, 0.04);
    }

    .icon {
      color: $grey-tone-700;
    }

    .title {
      color: $grey-tone-900;
    }

    .subtitle {
      color: $grey-tone-700;
    }

    .removeIcon {
      color: $grey-tone-900;
    }
  }

  &.blue {
    &:hover {
      background-color: rgba($blue-tone-600, 0.08);

      @include mobile-media-query {
        background-color: $white-tone;
      }
    }

    .iconContainer {
      background: rgba($blue-tone-600, 0.08);
    }

    .icon {
      color: $blue-tone-600;
    }

    .title {
      color: $blue-tone-600;
    }

    .subtitle {
      color: $blue-tone-600;
    }

    .removeIcon {
      color: $blue-tone-600;
    }
  }
}

.loadingContainer {
  display: flex;
  width: 100%;
  padding: 8px 20px;

  .loadingIcon {
    background-color: rgba($grey-tone-900, 0.04);
    width: 48px;
    height: 48px;
    border-radius: 8px;
  }

  .loadingContent {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex: 1;
    margin-left: 8px;

    .loadingLine {
      background-color: rgba($grey-tone-900, 0.04);
      height: 12px;
      width: 100%;
      border-radius: 4px;

      &:first-of-type {
        width: 75%;
      }
    }
  }
}
