@import "@livingmap/core-ui-v2";

@import "../../utils/animations.scss";

.container {
  display: flex;
  cursor: pointer;

  .content {
    flex: 1;
    margin-right: 12px;

    > p,
    span {
      @include font-body-medium;
      margin: 0;
    }

    .title {
      @include font-label-large-prominent;
      color: $grey-tone-900;
      margin-bottom: 4px;
    }

    .description,
    .address {
      @include font-body-medium;
      color: $grey-tone-700;
    }

    .tempClosed {
      @include font-body-medium;
      color: $red-tone-600;
    }
  }

  .imageContainer {
    flex-shrink: 0;
    width: 88px;
    height: 88px;
    border-radius: 8px;
    object-fit: cover;
  }
}

.loadingContainer {
  display: flex;
  padding-top: 20px;

  .loadingContent {
    flex: 1;
    margin-right: 12px;

    .loadingTitle,
    .loadingDescription,
    .loadingAddress,
    .loadingTimes {
      height: 14px;
      background: rgba($grey-tone-900, 0.04);
      border-radius: 4px;
      margin-bottom: 6px;
    }

    .loadingTitle {
      width: 160px;
    }

    .loadingDescription {
      width: 200px;
    }

    .loadingAddress {
      width: 140px;
    }

    .loadingTimes {
      width: 120px;
    }
  }

  .loadingImage {
    width: 88px;
    height: 88px;
    background: rgba($grey-tone-900, 0.04);
    border-radius: 8px;
  }
}

.skeletonBox {
  @extend .skeletonBoxAnimation;
}
