.timeToDestination {
  padding: 0 16px;
  min-height: 64px;
  order: -1;
}

.routeStepSlider {
  position: absolute;
  bottom: 0;
}
