@import "@livingmap/core-ui-v2";

.container {
  display: flex;
  height: 48px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;

  border-radius: 8px;
  border: 1px solid $grey-tone-300;

  .input {
    display: flex;
    padding: 1px 0px 2px 0px;
    align-items: center;
    flex: 1 0 0;
    background: transparent;
    border: none;
    outline: none;
    @include font-label-large;

    &::placeholder {
      color: $grey-tone-500;
    }
  }

  .clearButton {
    cursor: pointer;
  }
}
