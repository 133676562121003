@import "@livingmap/core-ui-v2";

.container {
  @include font-body-medium;
  color: $grey-tone-900;

  &.closed {
    color: $red-tone-600;
  }
}
