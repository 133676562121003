@import "@livingmap/core-ui-v2";

.container {
  .status {
    @include font-body-medium;
  }

  .chevron {
    width: 12px;
    margin-left: 10px;
    color: $grey-tone-700;
  }

  .title {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    color: $grey-tone-900;
    @include font-body-medium;
  }

  .times {
    margin-top: 10px;
  }

  .daysInfo {
    @include font-body-medium;
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-left: 41px;
    margin-top: 7px;

    .dayRow {
      display: flex;
      justify-content: flex-start;

      .active {
        @include font-body-medium-prominent;
      }

      .dayLabel {
        min-width: 100px;
      }

      .dayTimes {
        text-align: left;
        margin-left: 18px;
        display: grid;
      }
    }
  }
}
