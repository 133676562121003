@import "@livingmap/core-ui-v2";

.container {
  @include font-title-medium;
  padding: 16px 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;

  .liveIcon {
    color: $blue-tone-600;
    height: 8px;
    width: 8px;
    margin-right: 4px;
    position: relative;
    bottom: 6px;
  }

  .text,
  .subText {
    unicode-bidi: plaintext;
  }

  .text {
    color: $grey-tone-900;
  }

  .subText {
    color: $grey-tone-600;

    &.rtl {
      margin-left: 6px;
    }
  }
}
