@import "@livingmap/core-ui-v2";

// Styles for the sheet component
:global([data-rsbs-is-blocking="false"] [data-rsbs-overlay]) {
  box-shadow:
    0px 0px 12px rgba($black-tone, 0.15),
    0px 4px 12px 5px rgba($black-tone, 0.05);
  border-radius: 24px 24px 0px 0px;
}

:global([data-rsbs-header]) {
  box-shadow: none;
  padding: 20px;

  &:before {
    width: 48px;
  }
}
// ----------------------------------------------------------------

.panelContainer {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 1;
  width: 360px;
}

.header {
  display: flex;
  flex-direction: column;
  gap: 4px;

  .closeButtonContainer {
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 24px;
  }

  .title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;

    h2 {
      @include font-headline-small;
      color: $grey-tone-900;
      margin: 0 22px 0 0;
    }
  }

  .info {
    display: flex;
    padding-right: 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;

    .summary {
      @include font-body-medium;
      margin: 0;
      color: $grey-tone-700;
    }

    .temporarilyClosed {
      @include font-body-medium;
      color: $red-tone-600;
    }
  }
}

.horizontalScrollContainer {
  margin: 0 -20px;
  padding: 0 20px;
}

.content {
  padding-top: 0 !important;
}

.interactionButtons {
  display: flex;

  .directionsButton {
    margin-right: 12px;
    justify-content: center;

    svg {
      width: 14px;
    }
  }

  .shareButton {
    justify-content: center;
  }

  .callbackButton {
    background: linear-gradient(to right, $red-tone-600, $purple-tone-600);
    padding: 2px;
    border-radius: 100px;

    .button {
      height: 36px;
    }
  }
}

.activeTransportService {
  background: $grey-tone-900 !important;
  color: $white-tone;
}

.mobileContainer {
  .header {
    display: flex;
    margin-bottom: 20px;

    .title p {
      text-align: left;
    }
  }

  .metaData {
    margin-bottom: 20px;
  }

  .horizontalScrollContainer {
    margin-top: 20px;
  }
}
