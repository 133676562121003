@import "@livingmap/core-ui-v2";

@import "../../../../utils/variables.scss";

.container {
  .quickActions {
    padding-bottom: 10px;

    @include mobile-media-query {
      padding-bottom: 20px;
    }

    > div:first-child:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  .divider {
    display: block;
    height: 1px;
    background-color: $grey-tone-300;
    margin: 0 20px;

    @include mobile-media-query {
      margin: 0;
    }
  }

  .quickActionButton {
    padding: 10px 20px;
  }

  .recentPois {
    padding-top: 10px;

    &.noMarginTop {
      padding-top: 0;
    }
  }

  @include mobile-media-query {
    .recentPois {
      padding: 20px 0;
    }

    .quickActionButton {
      padding: 0;
    }
  }
}
